import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GetDefaultSEOImage } from "services/graphQl/queries/seoImage"
import freshpaintScript from "../../../views/welcomevisits/insuranceplan/freshpaintScript"

const Seo = ({
  abstract,
  canonical,
  description,
  image,
  keywords,
  lang,
  meta = [],
  schemaMarkup,
  slug,
  title,
  type,
}) => {
  const seoImage = image?.file?.url || GetDefaultSEOImage()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: canonical || `${site?.siteMetadata?.siteUrl}/${slug}`,
          rel: "canonical",
        },
      ]}
      meta={[
        {
          content: description?.description,
          name: "description",
        },
        {
          content: abstract?.abstract,
          name: "abstract",
        },
        {
          content: keywords?.join(", ") || "",
          name: "keywords",
        },
        {
          content: "Cortica",
          property: "og:site_name",
        },
        {
          content: title,
          property: "og:title",
        },
        {
          content: description?.description,
          property: "og:description",
        },
        {
          content: type,
          property: "og:type",
        },
        {
          content: seoImage,
          property: "og:image",
        },
        {
          content: seoImage,
          property: "og:image:url",
        },
      ].concat(meta)}
      title={title}
    >
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
      <script type="text/javascript">{freshpaintScript}</script>
    </Helmet>
  )
}

Seo.props = {
  abstract: PropTypes.shape({
    abstract: PropTypes.string,
  }),
  canonical: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }),
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      property: PropTypes.string,
    })
  ),
  schemaMarkup: PropTypes.object,
  slug: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
}

Seo.propTypes = Seo.props

export default Seo

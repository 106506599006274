import { useStaticQuery, graphql } from "gatsby"

export const GetDefaultSEOImage = () => {
  const query = useStaticQuery(
    graphql`
      query DefaultSEOImage {
        asset: contentfulAsset(title: { eq: "rowan2b" }) {
          file {
            url
          }
        }
      }
    `
  )
  return query.asset?.file?.url
}
